<template>
  <div id="shopGoods">
    <HomePageTop></HomePageTop>
    <!-- 店铺信息 -->
    <header v-if="oldShop==false">
      <div class="headerLeft">
        <img class="logo" src="../../assets/1688/1688logo.svg">
        <img class="headPortrait" src="../../assets/1688/shopHeadPortrait.png">
        <div>
          <div class="shopName">
            {{ $route.query.shopName == undefined ? shopDetails.shop_name : $route.query.shopName }}
          </div>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <div class="rateContainer">
                <div class="text">品質評点</div>
                <el-rate
                    v-model="sellerDataInfo.offerExperienceScore"
                    disabled
                    text-color="#ff9900">
                </el-rate>
              </div>
              <div class="rateContainer">
                <div class="text">物流レ評点</div>
                <el-rate
                    v-model="sellerDataInfo.logisticsExperienceScore"
                    disabled
                    text-color="#ff9900">
                </el-rate>
              </div>
              <div class="rateContainer">
                <div class="text">トラブル解決</div>
                <el-rate
                    v-model="sellerDataInfo.disputeComplaintScore"
                    disabled
                    text-color="#ff9900">
                </el-rate>
              </div>
              <div class="rateContainer">
                <div class="text">問い合わせ</div>
                <el-rate
                    v-model="sellerDataInfo.consultingExperienceScore"
                    disabled
                    text-color="#ff9900">
                </el-rate>
              </div>
            </div>
            <div slot="reference" class="rateContainer">
              <div class="text">総合評点</div>
              <el-rate
                  v-model="sellerDataInfo.compositeServiceScore"
                  disabled
                  text-color="#ff9900">
              </el-rate>
            </div>
          </el-popover>
        </div>
        <div>
          <div class="flexAndCenter">
            <button v-if="!shopFavoriteStatus"
                    :style="$route.query.powerful==undefined&&$route.query.super==undefined?'margin: -26px 0 0 12px;':''"
                    class="toFavorite"
                    @click="shopAddFavorite">
              <img alt="" src="../../assets/1688/collect.png">
              {{ $fanyi("收藏") }}
            </button>
            <button v-else
                    :style="$route.query.powerful==undefined&&$route.query.super==undefined?'margin: -26px 0 0 12px;':''"
                    class="toFavorite"
                    @click="favoriteShopDelete">
              <img alt="" src="../../assets/SearchResultsPage/Collected.png" style="width: 16px;height: 16px">
              {{ $fanyi("取消收藏") }}
            </button>
          </div>
          <div v-if="$route.query.powerful!=undefined||$route.query.super!=undefined" class="flexAndCenter"
               style="margin: 10px 0 0 10px;">
            <div v-if="$route.query.super!=undefined" :style="$route.query.powerful!=undefined?'margin-right: 6px':''"
                 class="powerfulMerchantsContainer">
              <img alt="" src="../../assets/1688/powerfulMerchantsIcon.png">
              <span>スーパーメーカー</span>
            </div>
            <div v-if="$route.query.powerful!=undefined"
                 class="superFactoryContainer">
              <img alt="" src="../../assets/1688/superFactoryIcon.png">
              <span>実力商家</span>
            </div>
          </div>
        </div>
      </div>
      <div class="headerRight">
        <div v-for="(item,index) in salesVolumeRankingList" :key="index" class="rankingItem"
             @click="$fun.toCommodityDetails(item.goodsId,item.shopType)">
          <img :src="item.imgUrl" alt="">
          <div>TOP{{ index + 1 }}</div>
        </div>
      </div>
    </header>
    <nav v-else>{{ $route.query.shopName }}>></nav>
    <!-- 店铺分类 -->
    <div v-if="shopCatesList.length>0" class="shopCatesContainer">
      <div class="shopCatesLabel">{{ $fanyi('分类') }}：</div>
      <div class="shopCatesListContainer">
        <div v-for="(item,index) in shopCatesList" :key="index"
             :style="form.categoryId==item.cate_id?'color: #B4272B;':''"
             @click="updateCategoryId(item)">
          {{ item.cate_name_ja }}
        </div>
      </div>
    </div>
    <!-- 店铺筛选 -->
    <section v-if="oldShop==false">
      <div class="sectionLeft">
        <div :class="bottomFiltrateIndex==0?'selectFiltrateContainer':'filtrateContainer'"
             @click="updateBottomFiltrateIndex(0)">総合ランキング
        </div>
        <div :class="bottomFiltrateIndex==1?'selectFiltrateContainer':'filtrateContainer'"
             @click="updateBottomFiltrateIndex(1)">
          <div>販売数</div>
          <img :src="monthlySalesImg" alt="" style="margin-top: 4px">
        </div>
        <div :class="bottomFiltrateIndex==2?'selectFiltrateContainer':'filtrateContainer'"
             @click="updateBottomFiltrateIndex(2)">
          <div>リピート率</div>
          <img :src="repurchaseRateImg" alt="" style="margin-top: 4px">
        </div>
        <div :class="bottomFiltrateIndex==3||bottomFiltrateIndex==4?'selectFiltrateContainer':'filtrateContainer'"
             @click="newUpdateBottomFiltrateIndex">
          <div>価格</div>
          <div class="iconContainer">
            <img :src="priceAscendingOrderImg" alt="" style="margin-bottom: 2px"
                 @click.stop="updateBottomFiltrateIndex(3)">
            <img :src="priceDescendingOrderImg" alt="" @click.stop="updateBottomFiltrateIndex(4)">
          </div>
        </div>
        <div class="priceContainer">
          <div class="flexAndCenter">
            <div class="label">￥</div>
            <el-input v-model="form.priceStart" placeholder="最安価格" type="number"
                      @keydown.native="channelInputLimit"/>
          </div>
          <div class="halvingLine">-</div>
          <div class="flexAndCenter">
            <div class="label">￥</div>
            <el-input v-model="form.priceEnd" placeholder="最高価格" type="number" @keydown.native="channelInputLimit"/>
          </div>
          <div class="btn" @click="lists = [];loading=true;form.page = 1; getData();">確認</div>
        </div>
      </div>
      <el-input v-model="form.keyword" placeholder="商品名を入力してください" prefix-icon="el-icon-search"
                style="width: 300px;display: flex;align-items: center">
        <template slot="append"><span @click="lists = [];loading=true;form.page = 1; getData();">検索</span></template>
      </el-input>
    </section>
    <!-- 店铺商品页 -->
    <div v-if="lists.length != 0" class="SearchConterCrosswise">
      <div
          v-for="(item, index) in lists"
          :key="index"
          class="SearchConterCrosswiseCon"
          @click="$fun.toCommodityDetails(item.goodsId,item.shopType)"
      >
        <!-- 放图片的div -->
        <div class="SearchConterCrosswiseImg">
          <el-image :src="item.imgUrl" class="goodsImg">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </div>
        <!-- 显示其他信息的div -->
        <div class="rests">
          <p :title="item.titleT" class="goodsTitle">
            <span class="type">1688</span>
            {{ item.titleT }}
          </p>
          <p>
            <span>{{
                $fun.yenNumSegmentation(Number((Number(item.goodsPrice) * Number(exchangeRate)).toFixed(0)))
              }}円（{{ $fun.roundFixed(Number(item.goodsPrice)) }}元）</span>
            <span v-if="oldShop==false"
                  style="color: #999999;font-size: 12px">販売数<span
                style="color: #B4272D!important;">{{ item.sales }}</span>件</span>
          </p>
          <div v-if="oldShop==false" class="repurchaseRate">リピート率
            <span>{{ item.repurchaseRate }}</span>％
          </div>
        </div>
      </div>
      <div v-show="keywordLoading" class="keywordLoadingContainer">
        <div class="flexAndCenter">
          <img alt="" src="@/assets/loading.gif">
          <div>読み込み中</div>
        </div>
      </div>
    </div>
    <div v-if="lists.length == 0" v-loading="loading" class="donNotHaveGoods">
      {{ txt }}
    </div>
    <Foot></Foot>
  </div>
</template>


<script>
import Foot from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  data() {
    return {
      form: {
        page: 1,
        shop_id: this.$route.query.shopId,
        shop_type: this.$route.query.shopType,
        pageSize: 50,
        categoryId: '',
        keyword: '',
        sort: undefined,
        priceStart: '',
        priceEnd: ''
      },
      shopDetails: {},
      sellerDataInfo: {
        compositeServiceScore: 0,
        consultingExperienceScore: 0,
        disputeComplaintScore: 0,
        logisticsExperienceScore: 0,
        offerExperienceScore: 0,
      },
      bottomFiltrateIndex: null,
      oldShop: false,
      salesVolumeRankingList: [],
      monthlySalesImg: require('../../assets/1688/bottomNoSelect.png'),
      priceAscendingOrderImg: require('../../assets/1688/topNoSelect.png'),
      priceDescendingOrderImg: require('../../assets/1688/bottomNoSelect.png'),
      repurchaseRateImg: require('../../assets/1688/bottomNoSelect.png'),
      total: 0,
      keyWord: "",
      lists: [],
      showList: [],
      shopCatesList: [],
      txt: "",
      loading: true,
      isLoading: true,
      keywordLoading: true,
      shopFavoriteStatus: false,
    };
  },
  components: {
    HomePageTop,
    Foot,
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  created() {
    this.getData();
    this.favoriteShopCheckIn();
    // if (this.oldShop === false && this.$route.query.wangName != undefined) {
    //   this.$api.shopCates({
    //     loginId: this.$route.query.wangName
    //   }).then((result) => {
    //     this.shopCatesList = result.data;
    //   })
    // }
  },
  methods: {
    // 获取列表数据
    getData() {
      if (this.form.page < 1) {
        this.form.page = 1;
      }
      if (this.loading === false) {
        this.keywordLoading = true
      }
      if (this.oldShop === false) {
        // 获取店铺商品数据
        if (this.form.sort != undefined && this.returnType(this.form.sort) != 'string') {
          this.form.sort = JSON.stringify(this.form.sort)
        }
        this.$api.getSellerOfferList(this.form).then((result) => {
          if (result.code != 0) {
            this.oldShop = true;
            this.getOldShop();
            return
          }
          if (this.returnType(result.data.sellerDataInfo) != 'array') {
            Object.keys(result.data.sellerDataInfo).forEach((key) => {
              result.data.sellerDataInfo[key] = Number(result.data.sellerDataInfo[key])
            })
            this.sellerDataInfo = result.data.sellerDataInfo;
          }
          this.shopDetails = result.data;
          if ((result.data.goods == undefined && this.form.categoryId == '') || (result.data.goods.length == 0 && this.form.categoryId == '')) {
            this.oldShop = true;
            this.getOldShop();
            return
          } else {
            this.loading = false;
            this.keywordLoading = false;
          }
          if (result.data.goods.length < 50) {
            this.isLoading = false;
          }
          result.data.goods.forEach((item) => {
            this.lists.push(item);
          });
          if (this.salesVolumeRankingList.length === 0) {
            this.salesVolumeRankingList = this.lists.sort((a, b) => b.sales - a.sales).slice(0, 5);
          }
          this.$forceUpdate();
        })
      } else {
        this.getOldShop();
      }
    },
    returnType(obj) {
      return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
    },
    //更新选中功能
    updateCategoryId(item) {
      if (this.form.categoryId == item.cate_id) {
        this.form.categoryId = ''
      } else {
        this.form.categoryId = item.cate_id
      }
      this.form.page = 1;
      this.lists = [];
      this.loading = true;
      this.keywordLoading = false;
      this.getData();
    },
    // 获取店铺商品数据
    getOldShop() {
      if (this.loading === false) {
        this.keywordLoading = true
      }
      // 获取店铺商品数据
      this.$api.getShopAllGoods({
        shop_name: this.$route.query.wangName == undefined ? this.$route.query.shopName : this.$route.query.wangName,
        shop_type: this.$route.query.shopType,
        page: this.form.page
      }).then((result) => {
        if (result.code != 0) {
          this.$message.error(this.$fanyi("此店铺暂无商品"));
          return
        }
        this.loading = false;
        this.keywordLoading = false;
        if (result.data.goods.length == 0) {
          this.txt = this.$fanyi("此店铺暂无商品")
          return
        }
        if (result.data.goods.length < 20) {
          this.isLoading = false;
        }
        result.data.goods.forEach((item) => {
          this.lists.push(item);
        });
        this.$forceUpdate();
      })
    },
    //查询某店铺是否已收藏
    favoriteShopCheckIn() {
      this.$api.shopCheckIn({
        shop_type: 1688,
        shop_name: [this.$route.query.shopName]
      }).then((res) => {
        if (res.code != 0) return false;
        this.shopFavoriteStatus = res.data[0].inFavorite;
      });
    },
    //店铺收藏移出
    favoriteShopDelete() {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      this.$api.shopDelete({
        shop_type: this.shopDetails.shop_type,
        shop_name: [this.$route.query.shopName]
      }).then((res) => {
        if (res.code != 0) return false;
        this.$message.success(res.msg);
        this.shopFavoriteStatus = !this.shopFavoriteStatus;
        this.$forceUpdate();
      });
    },
    //店铺添加收藏
    shopAddFavorite() {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      this.$api.shopAdd({
        shop_type: this.shopDetails.shop_type,
        shop_id: this.shopDetails.shop_id,
        shop_name: this.$route.query.shopName
      }).then((res) => {
        if (res.code != 0) return false;
        this.$message.success(res.msg);
        this.shopFavoriteStatus = !this.shopFavoriteStatus;
        this.$forceUpdate();
      });
    },
    channelInputLimit(e) {
      let key = e.key;
      key = key.replace(/１/g, '1')
      key = key.replace(/２/g, '2')
      key = key.replace(/３/g, '3')
      key = key.replace(/４/g, '4')
      key = key.replace(/５/g, '5')
      key = key.replace(/６/g, '6')
      key = key.replace(/７/g, '7')
      key = key.replace(/８/g, '8')
      key = key.replace(/９/g, '9')
      key = key.replace(/０/g, '0')
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key === "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    updateBottomFiltrateIndex(val) {
      this.bottomFiltrateIndex = val;
      switch (val) {
        case 0:
          this.monthlySalesImg = require('../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../assets/1688/bottomNoSelect.png');
          this.form.sort = undefined
          break;
        case 1:
          this.monthlySalesImg = require('../../assets/1688/bottomSelect.png');
          this.repurchaseRateImg = require('../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../assets/1688/bottomNoSelect.png');
          this.form.sort = {
            monthSold: 'desc'
          }
          break;
        case 2:
          this.monthlySalesImg = require('../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../assets/1688/bottomSelect.png');
          this.priceAscendingOrderImg = require('../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../assets/1688/bottomNoSelect.png');
          this.form.sort = {
            rePurchaseRate: 'desc'
          }
          break;
        case 3:
          this.monthlySalesImg = require('../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../assets/1688/topSelect.png');
          this.priceDescendingOrderImg = require('../../assets/1688/bottomNoSelect.png');
          this.form.sort = {
            price: 'asc'
          }
          break;
        case 4:
          this.monthlySalesImg = require('../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../assets/1688/bottomSelect.png');
          this.form.sort = {
            price: 'desc'
          }
          break;
      }
      this.lists = [];
      this.form.page = 1;
      this.loading = true;
      this.getData();
    },
    newUpdateBottomFiltrateIndex() {
      if (this.bottomFiltrateIndex != 3 && this.bottomFiltrateIndex != 4) {
        this.updateBottomFiltrateIndex(3);
      } else {
        if (this.bottomFiltrateIndex != 3) {
          this.updateBottomFiltrateIndex(3);
        } else {
          this.updateBottomFiltrateIndex(4);
        }
      }
    },
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      let numHeight = scrollTop + clientHeight;
      if (numHeight > scrollHeight - 600 && this.keywordLoading === false && this.isLoading === true) {
        this.keywordLoading = true
        this.fn()
      }
    },
    fn() {
      if (this.keywordLoading === true) {
        this.form.page++;
        this.getData();
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.getbottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getbottom);
  },
};
</script>
<style lang='scss' scoped>
#shopGoods {
  @import "../../css/mixin";
  width: 100%;
  background-color: #fafafa;

  nav {
    width: $pageWidth;
    margin: 30px auto;
    height: 64px;
    background: #f0f0f0;
    border-radius: 6px;
    padding: 0 20px;
    line-height: 24px;

    font-size: 18px;
    display: flex;
    align-items: center;
  }

  header {
    width: 1400px;
    height: 100px;
    background: linear-gradient(0deg, #FFFFFF, #FCF1EE);
    border-radius: 6px;
    margin: 24px auto 12px;
    padding: 0 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerLeft {
      display: flex;
      align-items: center;

      .logo {
        width: 86px;
        height: 32px;
        margin-right: 20px;
      }

      .headPortrait {
        width: 60px;
        height: 60px;
        margin-right: 15px;
      }

      .shopName {
        font-weight: bold;
        color: #333333;
        font-size: 20px;
        margin-bottom: 8px;
      }

      button {
        height: 26px;
        background: #B4272B;
        border-radius: 4px;
        padding: 0 6px;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        margin: 12px 0 0 12px;

        img {
          margin-right: 4px;
        }
      }
    }

    .headerRight {
      display: flex;

      .rankingItem {
        position: relative;
        width: 80px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 5px;
        margin-right: 14px;
        cursor: pointer;

        img {
          width: 70px;
          height: 70px;
        }

        div {
          width: 36px;
          height: 16px;
          background: #E99D52;
          border-radius: 2px;
          color: #FFFFFF;
          font-size: 12px;
          text-align: center;
          position: absolute;
          left: 22.5px;
          bottom: 10px;
        }
      }

      .rankingItem:last-child {
        margin-right: 0;
      }
    }
  }

  .shopCatesContainer {
    width: 1400px;
    margin: 0 auto 12px;
    border-radius: 6px;
    padding: 25px 36px 15px;
    background: #FFFFFF;
    display: flex;

    .shopCatesLabel {
      min-width: 81px;
      margin-right: 10px;
      color: #999999;
    }

    .shopCatesListContainer {
      display: flex;
      flex-wrap: wrap;

      div {
        color: #000000;
        margin: 0 42px 10px 0;
        cursor: pointer;
      }

      div:last-child {
        margin-right: 0;
      }
    }
  }

  section {
    width: 1400px;
    height: 90px;
    border-radius: 6px;
    background: #FFFFFF;
    margin: 0 auto 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 35px;

    .sectionLeft {
      display: flex;
      align-items: center;

      .filtrateContainer, .selectFiltrateContainer {
        display: flex;
        align-items: center;
        margin-right: 60px;
        cursor: pointer;

        div {
          margin-right: 5px;
        }

        .iconContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 2px;
        }
      }

      .selectFiltrateContainer {
        font-weight: bold;
        color: #B4272B;
      }

      .priceContainer {
        display: flex;
        align-items: center;

        .flexAndCenter {
          width: 100px;
          height: 30px;
          background: #FFFFFF;
          border: 1px solid #999999;
          border-radius: 6px;
          padding-left: 12px;

          .label {
            color: #000000;
            font-weight: 400 !important;
            font-size: 14px !important;
          }

          /deep/ .el-input__inner {
            height: 28px;
            border: none;
            padding: 0;
          }
        }

        .halvingLine {
          margin: -6px 6px 0;
          color: #999999;
          font-size: 30px;
        }

        .btn {
          margin-left: 10px;
          width: 56px;
          height: 30px;
          background: #B4272B;
          border-radius: 6px;
          line-height: 30px;
          cursor: pointer;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          color: #fff;
        }
      }
    }

    /deep/ .el-input__inner {
      border: 2px solid #B4272B;
      height: 54px;
    }

    /deep/ .el-input-group__append {
      width: 64px;
      min-width: 64px;
      border-radius: 0 4px 4px 0;
      background: #B4272B;
      padding: 0;
      color: #fff;
      height: 56px;
      min-height: 56px;
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;
      line-height: 56px;
    }
  }

  .keywordLoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1400px;

    img {
      width: 40px;
      margin-right: 20px;
    }

    div {
      font-size: 14px;
    }
  }

  .bodyTitle {
    width: $pageWidth;
    margin: 0 auto 20px;
    font-size: 24px;
    color: #000000;
    line-height: 36px;
  }

  .SearchConterCrosswise {
    width: $pageWidth;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    min-height: 500px;

    > .SearchConterCrosswiseCon {
      margin-right: 18.5px;
      margin-bottom: 20px;
      position: relative;
      transition: 0.3s;

      &:hover {
        transform: translate(0, -5px);
        box-shadow: 0 10px 20px #d1d1d1;

        .similarProducts {
          opacity: 1;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      background-color: white;
      width: 265px;
      border: solid 1px #e8e8e8;
      border-radius: 6px;
      overflow: hidden;

      > div {
        cursor: pointer;

        &.SearchConterCrosswiseImg {
          width: 265px;
          height: 265px;
          position: relative;

          > .goodsImg {
            background-color: transparent;
          }
        }

        &.rests {
          box-sizing: border-box;
          padding: 7px 10px 14px;

          p {
            font-size: 12px;

            font-weight: 400;
            color: #000000;

            &.goodsTitle {
              height: 40px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 14px;
              color: #000000;
              line-height: 20px;

              .type {
                width: 46px;
                height: 18px;
                display: inline-block;
                background: #FF4000;
                border-radius: 2px;
                margin-right: 6px;
                text-align: center;
                line-height: 18px;
                font-weight: bold;
                color: #fff;
              }
            }

            &:nth-child(2) {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              > span {
                font-size: 12px;
                color: #B4272B;
              }
            }

            .collect {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              span {
                font-size: 14px;

                color: #888888;
                line-height: 20px;
              }
            }
          }

          .repurchaseRate {
            width: 100px;
            height: 24px;
            background: #F6F6F6;
            border-radius: 4px;
            line-height: 24px;
            text-align: center;
            color: #333333;
            font-size: 12px;
            margin-top: 6px;

            span {
              color: #B4272D;
            }
          }
        }
      }
    }

    > .SearchConterCrosswiseCon:nth-child(5n) {
      margin-right: 0;
    }
  }

  .donNotHaveGoods {
    width: $pageWidth;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
    font-size: 35px;
    color: #888888;
  }

  .allpag {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 25px;

    div {
      cursor: pointer;
      background: #B4272B;
      padding: 5px 10px;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
    }
  }
}

/deep/ .el-image__inner {
  width: 265px;
  height: 265px;
  min-width: 265px;
  min-height: 265px;
}

/deep/ .el-image.goodsImg {
  $imgSize: 265px;
  width: $imgSize;
  height: $imgSize;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}

.superFactoryContainer, .powerfulMerchantsContainer {
  width: 88px;
  height: 24px;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 17px;
    margin-right: 5px;
  }

  span {
    color: #F72A2B;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
  }
}

.powerfulMerchantsContainer {
  background: #F8F6FF !important;
  width: 136px !important;

  span {
    color: #3700E1 !important;
  }
}
</style>
